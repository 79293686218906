import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import '../building/project.scss';
import '../company/duplicate-buildcard.scss.scss';
import BuildcardComp from '../../shared/shared-comp/BuildcardComp';
import images from '../../assets/images';

export default function JourneysFeaturesTable() {
  //=================================================
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  return (
    <div className="attributes-section">
      <div className="attributes-table Journey-table" style={{ width: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ background: '#F3EBFF' }}>
                  <div className="d-flex align-items-center">
                    Buildcard{' '}
                    <span className="status-tags" style={{ marginLeft: '5px' }}>
                      Draft
                    </span>
                  </div>
                </TableCell>
                <TableCell>Next big idea 1</TableCell>
                <TableCell>Next big idea 2</TableCell>
                <TableCell>Next big idea 3</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell scope="row">
                  <div className="d-flex justify-content-between align-items-center">
                    Set up user account{' '}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen1(!open1)}
                    >
                      {open1 ? (
                        <img src={images.iconMinus} alt="minus" />
                      ) : (
                        <em className="icon be-plus" />
                      )}
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell scope="row">-</TableCell>
                <TableCell scope="row">
                  <strong>16</strong> out of <strong>25</strong> common features
                </TableCell>
                <TableCell scope="row">
                  <strong>22</strong> out of <strong>25</strong> common features
                </TableCell>
              </TableRow>
              <TableRow className="collapse-table">
                <TableCell className="blank" style={{}} colSpan={4}>
                  <Collapse in={open1} timeout="auto" unmountOnExit>
                    <Box>
                      <Table
                        className="inner-table"
                        size="small"
                        aria-label="purchases1"
                      >
                        <TableHead style={{ display: 'none' }}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row">
                              Splash screen{' '}
                              <Tooltip
                                className="note-tooltip"
                                placement="right"
                                title={
                                  <span className="note-info">
                                    <strong>Heading goes here</strong>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                  </span>
                                }
                              >
                                <Button className="note-btn">view note</Button>
                              </Tooltip>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick">
                                <Tooltip
                                  className="note-tooltip"
                                  placement="top"
                                  title={
                                    <span className="note-info">
                                      <strong>Heading goes here</strong>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit, sed do eiusmod tempor
                                      incididunt ut labore et dolore magna
                                      aliqua. Ut enim ad minim veniam, quis
                                      nostrud exercitation ullamco laboris nisi
                                      ut aliquip ex ea commodo consequat duis
                                      aute irure dolor in reprehenderit in
                                      voluptate velit esse cillum dolore eu
                                      fugiat nulla pariatur.
                                    </span>
                                  }
                                >
                                  <Button className="note-btn">
                                    view note
                                  </Button>
                                </Tooltip>
                              </span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Trending video{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row">
                              Login/signup module{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row">
                              Sort and arrange in order{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell scope="row">
                              Schedule calender{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Terms and conditions{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  <div className="d-flex justify-content-between align-items-center">
                    Make recommendations{' '}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen2(!open2)}
                    >
                      {open2 ? (
                        <img src={images.iconMinus} alt="minus" />
                      ) : (
                        <em className="icon be-plus" />
                      )}
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell scope="row">
                  <strong>12</strong> out of <strong>25</strong> common features
                </TableCell>
                <TableCell scope="row">
                  <strong>16</strong> out of <strong>25</strong> common features
                </TableCell>
                <TableCell scope="row">
                  <strong>22</strong> out of <strong>25</strong> common features
                </TableCell>
              </TableRow>

              <TableRow className="collapse-table">
                <TableCell className="blank" colSpan={4}>
                  <Collapse in={open2} timeout="auto" unmountOnExit>
                    <Box>
                      <Table
                        className="inner-table"
                        size="small"
                        aria-label="purchases"
                      >
                        <TableHead style={{ display: 'none' }}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row">
                              Terms and conditions{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"> </span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Bulk uploading{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Sort and arrange in order{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Search{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell scope="row">
                  <div className="d-flex justify-content-between align-items-center">
                    Setup account & support{' '}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <img src={images.iconMinus} alt="minus" />
                      ) : (
                        <em className="icon be-plus" />
                      )}
                    </IconButton>
                  </div>
                </TableCell>
                <TableCell scope="row">
                  <strong>12</strong> out of <strong>25</strong> common features
                </TableCell>
                <TableCell scope="row">
                  <strong>16</strong> out of <strong>25</strong> common features
                </TableCell>
                <TableCell scope="row">
                  <strong>22</strong> out of <strong>25</strong> common features
                </TableCell>
              </TableRow>

              <TableRow className="collapse-table">
                <TableCell className="blank" colSpan={4}>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                      <Table
                        className="inner-table"
                        size="small"
                        aria-label="purchases"
                      >
                        <TableHead style={{ display: 'none' }}>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell scope="row">
                              Terms and conditions{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"> </span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Bulk uploading{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Sort and arrange in order{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell scope="row">
                              Search{' '}
                              <Button className="note-btn">view note</Button>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-tick"></span>
                            </TableCell>
                            <TableCell scope="row">
                              <span className="icon be-close"></span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
