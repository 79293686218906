import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Tab,
  Tooltip,
} from '@mui/material';
import { startTransition, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import '../building/project.scss';
import '../company/duplicate-buildcard.scss.scss';
import images from '../../assets/images';
import AttributesTable from './AttributesTable';
import JourneysFeaturesTable from './JourneysFeaturesTable';
import RightPageHeader from '../../shared/shared-comp/RightPageHeader';

export default function DuplicateBuildcard() {
  const [value, setValue] = React.useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [value1, setValue1] = React.useState('1');
  const handleChange1 = (event: React.SyntheticEvent, newValue: string) => {
    setValue1(newValue);
  };
  const [open, setOpen] = React.useState(false);
  //=================================================

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const [isActive, setIsActive] = useState(false);

  // Handler to add the class to the body
  const handleAddClass = () => {
    setIsActive(true);
    document.body.classList.add('active-class');
  };

  // Handler to remove the class from the body
  const handleRemoveClass = () => {
    setIsActive(false);
    document.body.classList.remove('active-class');
  };

  const handleCombinedClick = (event) => {
    handleClick(0); // Call your first click handler
    // Call any other functionality you want here
  };

  const handleClick1 = (index) => {
    // Your existing handleClick functionality
  };

  return (
    <div className="duplicate-buildcard-page ">
      <div className="page-container top-space">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li>
                  <a href="#">Building</a>
                </li>
                <li>
                  <a href="#">Spaces</a>
                </li>
                <li>
                  <a href="#">Supply chain & distribution</a>
                </li>
                <li>
                  <a href="#"> ideaboard</a>
                </li>
                <li>
                  <a href="#">Warehouse Scanner</a>
                </li>
                <li>Duplication review</li>
              </ul>
            </div>
            <RightPageHeader />
          </div>

          <div className="member-title ">
            <div>
              <h2 style={{ marginBottom: '0' }}>
                Warehouse scanner - Similar Buildcards
              </h2>
              {/* <p>Add a description for your space</p> */}
            </div>
          </div>
        </div>

        <div className="tab-contest">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Overview" value="1" />
                  <Tab label="Comparison" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div
                  className={`duplicate-buildcard-section ${activeIndex === 0 ? 'active' : ''}`}
                >
                  <div className="col">
                    <div className="dup-buildcard-left">
                      <h3>Your Buildcard</h3>
                      <div className="card-template active">
                        <div className="card-body">
                          <div className="tag-container has-right-content">
                            <Stack direction="row" spacing={1}>
                              <Chip className="grey" label="Draft" />
                            </Stack>
                            <div className="icon-btn-wrap">
                              <Button className="btn-goal-rounded">
                                <em className="icon be-business-goals"></em>
                                <span>0</span>
                              </Button>
                            </div>
                          </div>
                          <div className="inner-container">
                            <div className="left">
                              <div className="card-title-container">
                                <span
                                  className="card-name"
                                  aria-label="airbnb staging"
                                >
                                  airbnb staging
                                </span>
                                <div className="date-container">
                                  <span>9 weeks</span>
                                </div>
                                <div className="sub-heading">
                                  Lead: Rajneesh Sharma
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-tags-wrap">
                            <div className="tag-wrapper">
                              <div className="icon-btn-wrap">
                                <Button className="xs-btn is-icon-btn purple-btn has-border">
                                  <em className="icon be-tag-pin"></em>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="content-box">
                            <span className="title">FEATURES</span>
                            <div className="count">49</div>
                          </div>
                          <div className="content-box">
                            <span className="title">Platforms</span>
                            <div className="count">3</div>
                          </div>
                          <div className="content-box">
                            <span className="title">CREDITS</span>
                            <div className="count">120</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col hide justify-content-center">
                    <div className="similar-buildcard-block active">
                      <div className="line"></div>
                      <h3>Similar Buildcard</h3>
                      <div onClick={handleCombinedClick}>
                        <div
                          className={`similar-card first-child  ${activeIndex === 0 ? 'active' : ''}`}
                          onClick={() => handleClick(0)}
                        >
                          <div className="dividerRow"></div>
                          <div className="head">
                            <span>Buildcard</span>
                            Next big idea 1.0
                          </div>
                          <div className="similar-info">
                            <Tooltip
                              className="progress-tooltip-popover"
                              placement="bottom"
                              enterTouchDelay={0}
                              title={
                                <div className="credits">
                                  <span>65%</span>
                                </div>
                              }
                            >
                              <span>
                                <div className="progress-with-bg">
                                  <CircularProgress
                                    size={48}
                                    variant="determinate"
                                    className="bg-progress"
                                    value={100}
                                  />
                                  <CircularProgress
                                    size={48}
                                    variant="determinate"
                                    value={40}
                                  />
                                  <span className="progress-val">65%</span>
                                </div>
                              </span>
                            </Tooltip>
                            <div className="comm-journey">
                              <p>
                                <strong>10</strong> common journey
                              </p>
                              <p>
                                <strong>10</strong> common journey
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`similar-card ${activeIndex === 1 ? 'active' : ''}`}
                        onClick={() => handleClick(1)}
                      >
                        <div className="dividerRow"></div>
                        <div className="head">
                          <span>Buildcard</span>
                          Next big idea 1.0
                        </div>
                        <div className="similar-info">
                          <Tooltip
                            className="progress-tooltip-popover"
                            placement="bottom"
                            enterTouchDelay={0}
                            title={
                              <div className="credits">
                                <span>65%</span>
                              </div>
                            }
                          >
                            <span>
                              <div className="progress-with-bg">
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  className="bg-progress"
                                  value={100}
                                />
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  value={40}
                                />
                                <span className="progress-val">65%</span>
                              </div>
                            </span>
                          </Tooltip>
                          <div className="comm-journey">
                            <p>
                              <strong>10</strong> common journey
                            </p>
                            <p>
                              <strong>32</strong> common features
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`similar-card ${activeIndex === 2 ? 'active' : ''}`}
                        onClick={() => handleClick(2)}
                      >
                        <div className="dividerRow"></div>
                        <div className="head">
                          <span>Buildcard</span>
                          Next big idea 1.0
                        </div>
                        <div className="similar-info">
                          <Tooltip
                            className="progress-tooltip-popover"
                            placement="bottom"
                            enterTouchDelay={0}
                            title={
                              <div className="credits">
                                <span>65%</span>
                              </div>
                            }
                          >
                            <span>
                              <div className="progress-with-bg">
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  className="bg-progress"
                                  value={100}
                                />
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  value={40}
                                />
                                <span className="progress-val">65%</span>
                              </div>
                            </span>
                          </Tooltip>
                          <div className="comm-journey">
                            <p>
                              <strong>10</strong> common journey
                            </p>
                            <p>
                              <strong>10</strong> common journey
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`similar-card ${activeIndex === 3 ? 'active' : ''}`}
                        onClick={() => handleClick(3)}
                      >
                        <div className="dividerRow"></div>
                        <div className="head">
                          <span>Buildcard</span>
                          Next big idea 1.0
                        </div>
                        <div className="similar-info">
                          <Tooltip
                            className="progress-tooltip-popover"
                            placement="bottom"
                            enterTouchDelay={0}
                            title={
                              <div className="credits">
                                <span>65%</span>
                              </div>
                            }
                          >
                            <span>
                              <div className="progress-with-bg">
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  className="bg-progress"
                                  value={100}
                                />
                                <CircularProgress
                                  size={48}
                                  variant="determinate"
                                  value={40}
                                />
                                <span className="progress-val">65%</span>
                              </div>
                            </span>
                          </Tooltip>
                          <div className="comm-journey">
                            <p>
                              <strong>10</strong> common journey
                            </p>
                            <p>
                              <strong>10</strong> common journey
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col justify-content-end">
                    <div className="dup-buildcard-info active ">
                      <em className="icon be-corner-arrow"></em>
                      <div className="head">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div className="supply-chain">
                              <em className="icon be-multiple-users"></em>{' '}
                              Supply chain
                            </div>
                            <p>Next big idea 2.0</p>
                          </div>
                          <div>
                            <Tooltip
                              className="progress-tooltip-popover"
                              placement="bottom"
                              enterTouchDelay={0}
                              title={
                                <div className="credits">
                                  <span>65%</span>
                                </div>
                              }
                            >
                              <span>
                                <div className="progress-with-bg">
                                  <CircularProgress
                                    size={48}
                                    variant="determinate"
                                    className="bg-progress"
                                    value={100}
                                  />
                                  <CircularProgress
                                    size={48}
                                    variant="determinate"
                                    value={40}
                                  />
                                  <span className="progress-val">65%</span>
                                </div>
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="save-credits">
                          <span className="save-title">You will save</span>
                          <div className="save-credits-row">
                            <span>ON Timeline:</span>
                            <span>6 weeks</span>
                          </div>
                          <div className="save-credits-row">
                            <span>ON credits:</span>
                            <span>100 credits</span>
                          </div>
                        </div>
                      </div>
                      <div className="save-credits-info">
                        <div className="credits-row">
                          <span>Created</span>
                          <span>12 Nov 2024</span>
                        </div>
                        <div className="credits-row">
                          <span>All journey</span>
                          <span>12</span>
                        </div>
                        <div className="credits-row">
                          <span>All features</span>
                          <span>56</span>
                        </div>
                        <div className="credits-row">
                          <span>Platform</span>
                          <span>
                            <img src={images.iconAndroid} alt="Android" />
                            <img src={images.iconWebsite} alt="Website" />
                          </span>
                        </div>
                        <div className="credits-row">
                          <span>Expected delivery</span>
                          <span>12 Nov 2024</span>
                        </div>
                        <div className="credits-row">
                          <span>Credits</span>
                          <span>220</span>
                        </div>
                      </div>
                      <div className="lead-col">
                        <div className="user-avatar">
                          <img src={(images as any).userImage2} alt="" />
                        </div>
                        <div className="user-name">
                          Lead
                          <span>Akash Kumar </span>
                        </div>
                      </div>
                      <div className="view-btn">
                        <Button className="xs-btn purple-btn btn-full">
                          View Buildcard
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div>
                  <Box className="buildcard-sub-tab">
                    <TabContext value={value1}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={handleChange1}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Attributes" value="1" />
                          <Tab label="Journeys and features" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <AttributesTable />
                      </TabPanel>
                      <TabPanel value="2">
                        <JourneysFeaturesTable />
                      </TabPanel>
                    </TabContext>
                  </Box>
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}
