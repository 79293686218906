import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    setGoalsGraphReset: (state, action) => {
      return { goalGraph: action.payload }
    },
    currentGoalDetail: (state, action) => {
      return { ...state, goalDetail: action.payload }
    },
  },
})

export const { setGoalsGraphReset, currentGoalDetail } = goalsSlice.actions;

export const goalGraphState = (state) => state.goals?.goalGraph;
export const currentGoalDetailState = (state) => state?.goals?.goalDetail;

export default goalsSlice.reducer;
