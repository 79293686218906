import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import '../building/project.scss';
import '../company/duplicate-buildcard.scss.scss';
import images from '../../assets/images';
export default function AttributesTable() {
  //=================================================

  return (
    <>
      <div className="duplicate-buildcard-section">
        <div className="attributes-section">
          <div className="attributes-table left">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Attributes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">Similarity</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Space</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Progress</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Status</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Platform</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Credit</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Created on</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Delivery</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Journeys</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Features</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="height" scope="row">
                      Business goals
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">Lead</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="attributes-table">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ background: '#F3EBFF' }}>
                      <div className="d-flex align-items-center">
                        Buildcard{' '}
                        <span
                          className="status-tags"
                          style={{ marginLeft: '5px' }}
                        >
                          Draft
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>Next big idea 1</TableCell>
                    <TableCell>Next big idea 2</TableCell>
                    <TableCell>Next big idea 3</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">-</TableCell>
                    <TableCell scope="row">
                      <strong className="progress">
                        <img src={images.ProgresImg} alt="" /> 62%
                      </strong>
                    </TableCell>
                    <TableCell scope="row">
                      <strong className="progress">
                        <img src={images.ProgresImg} alt="" /> 62%
                      </strong>
                    </TableCell>
                    <TableCell scope="row">
                      <strong className="progress">
                        <img src={images.ProgresImg} alt="" /> 36%
                      </strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">
                      <div className="supply-chain">
                        <em className="icon be-multiple-users"></em> Supply
                        chain
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="supply-chain">
                        <em className="icon be-multiple-users"></em> Supply
                        chain
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="supply-chain">
                        <em className="icon be-multiple-users"></em> Supply
                        chain
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="supply-chain">
                        <em className="icon be-multiple-users"></em> Supply
                        chain
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">-</TableCell>
                    <TableCell scope="row">25%</TableCell>
                    <TableCell scope="row">56%</TableCell>
                    <TableCell scope="row">70%</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <span className="status-tags">Draft</span>
                    </TableCell>
                    <TableCell scope="row">
                      <span
                        className="status-tags"
                        style={{ background: '#6200EA' }}
                      >
                        In progress
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <span
                        className="status-tags"
                        style={{ background: '#FFAB00' }}
                      >
                        Pending approval
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <span
                        className="status-tags"
                        style={{ background: '#FFAB00' }}
                      >
                        Pending approval
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <span className="platform-col">
                        <img src={images.iconAndroid} alt="Android" />
                        <img src={images.iconWatchOS} alt="Android" />
                        <img src={images.iconWebsite} alt="Website" />
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <span className="platform-col">
                        <img src={images.iconAndroid} alt="Android" />{' '}
                        <img src={images.iconWebsite} alt="Website" />
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <span className="platform-col">
                        <img src={images.iconAndroid} alt="Android" />{' '}
                        <img src={images.iconWebsite} alt="Website" />
                      </span>
                    </TableCell>
                    <TableCell scope="row">
                      <span className="platform-col">
                        <img src={images.iconAndroid} alt="Android" />{' '}
                        <img src={images.iconWebsite} alt="Website" />
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">1200</TableCell>
                    <TableCell scope="row">1200</TableCell>
                    <TableCell scope="row">1200</TableCell>
                    <TableCell scope="row">1200</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">-</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                    <TableCell scope="row">12 Nov 2024</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">8</TableCell>
                    <TableCell scope="row">12</TableCell>
                    <TableCell scope="row">11</TableCell>
                    <TableCell scope="row">9</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">23</TableCell>
                    <TableCell scope="row">31</TableCell>
                    <TableCell scope="row">26</TableCell>
                    <TableCell scope="row">34</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell scope="row">
                      <Chip label="Reduce delivery time by 2 days" />
                      <Chip label="Reduce shipping cost by 10%" />
                    </TableCell>
                    <TableCell scope="row">
                      <Chip label="Reduce shipping cost by 10%" />
                    </TableCell>
                    <TableCell scope="row">
                      <Chip label="Reduce delivery time by 2 days" />
                      <Chip label="Reduce shipping cost by 10%" />
                    </TableCell>
                    <TableCell scope="row">
                      <Chip label="Reduce shipping cost by 10%" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row">
                      <div className="lead-name">
                        <em className="initials">A</em> Akash Kumar
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="lead-name">
                        <em className="initials">A</em> Akash Kumar
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="lead-name">
                        <em className="initials">A</em> Akash Kumar
                      </div>
                    </TableCell>
                    <TableCell scope="row">
                      <div className="lead-name">
                        <em className="initials">A</em> Akash Kumar
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
